$(()=>{

  $('#servicing_modal_submit').on('click', ()=>{
    if ($('#servicing_modal_form')[0].checkValidity()) {
      $('#servicing_modal_form').trigger('submit')
    } else {
      $('#servicing_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_adjust_reserves_modal_submit').on('click', ()=>{
    if ($('#servicing_adjust_reserves_modal_form')[0].checkValidity()) {
      $('#servicing_adjust_reserves_modal_form').trigger('submit')
    } else {
      $('#servicing_adjust_reserves_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_adjust_expenses_modal_submit').on('click', ()=>{
    if ($('#servicing_adjust_expenses_modal_form')[0].checkValidity()) {
      $('#servicing_adjust_expenses_modal_form').trigger('submit')
    } else {
      $('#servicing_adjust_expenses_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_adjust_recoveries_modal_submit').on('click', ()=>{
    if ($('#servicing_adjust_recoveries_modal_form')[0].checkValidity()) {
      $('#servicing_adjust_recoveries_modal_form').trigger('submit')
    } else {
      $('#servicing_adjust_recoveries_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_adjust_losses_modal_submit').on('click', ()=>{
    if ($('#servicing_adjust_losses_modal_form')[0].checkValidity()) {
      $('#servicing_adjust_losses_modal_form').trigger('submit')
    } else {
      $('#servicing_adjust_losses_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_add_adjuster_modal_submit').on('click', ()=>{
    if ($('#servicing_add_adjuster_modal_form')[0].checkValidity()) {
      $('#servicing_add_adjuster_modal_form').trigger('submit')
    } else {
      $('#servicing_add_adjuster_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_add_tpa_modal_submit').on('click', ()=>{
    if ($('#servicing_add_tpa_modal_form')[0].checkValidity()) {
      $('#servicing_add_tpa_modal_form').trigger('submit')
    } else {
      $('#servicing_add_tpa_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_create_cxl_notice_modal_submit').on('click', (e)=>{
    if ($('#servicing_create_cxl_notice_modal_form')[0].checkValidity()) {
      $('#servicing_create_cxl_notice_modal_form').trigger('submit')
      e.target.disabled = true
      $('#servicing_create_cxl_notice_modal_form').addClass('loading')
    } else {
      $('#servicing_create_cxl_notice_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_reinstate_policy_modal_submit').on('click', (e)=>{
    if ($('#servicing_reinstate_policy_modal_form')[0].checkValidity()) {
      $('#servicing_reinstate_policy_modal_form').trigger('submit')
      e.target.disabled = true
      $('#servicing_reinstate_policy_modal_form').addClass('loading')
    } else {
      $('#servicing_reinstate_policy_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_cancel_policy_modal_submit').on('click', (e)=>{
    if ($('#servicing_cancel_policy_modal_form')[0].checkValidity()) {
      $('#servicing_cancel_policy_modal_form').trigger('submit')
      e.target.disabled = true
      $('#servicing_cancel_policy_modal_form').addClass('loading')
    } else {
      $('#servicing_cancel_policy_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_flat_cancel_policy_modal_submit').on('click', (e)=>{
    if ($('#servicing_flat_cancel_policy_modal_form')[0].checkValidity()) {
      if (confirm("Are you sure you want to flat cancel this policy? This will cancel the policy to inception and return ALL fees/premium.")) {
        $('#servicing_flat_cancel_policy_modal_form').trigger('submit')
        e.target.disabled = true
        $('#servicing_flat_cancel_policy_modal_form').addClass('loading')
      } else {
        e.preventDefault()
      }
    } else {
      $('#servicing_flat_cancel_policy_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_create_cancellation_notice_by_finance_company_modal_submit').on('click', (e)=>{
    if ($('#servicing_create_cancellation_notice_by_finance_company_modal_form')[0].checkValidity()) {
      $('#servicing_create_cancellation_notice_by_finance_company_modal_form').trigger('submit')
      e.target.disabled = true
      $('#servicing_create_cancellation_notice_by_finance_company_modal_form').addClass('loading')
    } else {
      $('#servicing_create_cancellation_notice_by_finance_company_modal_form')[0].reportValidity()
    }
  })

  $('.servicing_cancel_policy_noc_modal_submit').on('click', (e)=> {
    let $clicked = $(e.target)
    let notice_uuid = $clicked.data('notice_uuid')

    if ($(`#servicing_cancel_policy_noc_modal_${notice_uuid}_form`)[0].checkValidity()) {
      if (confirm("Are you sure you want to cancel this policy?")) {
        $(`#servicing_cancel_policy_noc_modal_${notice_uuid}_form`).trigger('submit')
        $clicked.disabled = true
        $(`#servicing_cancel_policy_noc_modal_${notice_uuid}_form`).addClass('loading')
      } else {
        e.preventDefault()
      }
    }
  })

  $('.servicing_apply_overpayment_modal_submit').on('click', (e)=> {
    let $clicked = $(e.target)
    let policy_number = $clicked.data('policy_number')

    if ($(`#servicing_apply_overpayment_modal_${policy_number}_form`)[0].checkValidity()) {
      if (confirm("Are you sure you want to move this balance?")) {
        $(`#servicing_apply_overpayment_modal_${policy_number}_form`).trigger('submit')
        $clicked.disabled = true
        $(`#servicing_apply_overpayment_modal_${policy_number}_form`).addClass('loading')
      } else {
        e.preventDefault()
      }
    } else {
      $(`#servicing_apply_overpayment_modal_${policy_number}_form`)[0].reportValidity()
    }
  })

  $('.servicing_revoke_noc_modal_submit').on('click', (e)=> {
    let $clicked = $(e.target)
    let notice_uuid = $clicked.data('notice_uuid')

    if ($(`#servicing_revoke_noc_modal_${notice_uuid}_form`)[0].checkValidity()) {
      if (confirm("Are you sure you want to revoke this notice?")) {
        $(`#servicing_revoke_noc_modal_${notice_uuid}_form`).trigger('submit')
        $clicked.disabled = true
        $(`#servicing_revoke_noc_modal_${notice_uuid}_form`).addClass('loading')
      } else {
        e.preventDefault()
      }
    }
  })

  $('#servicing_non_renew_policy_modal_submit').on('click', (e)=> {
    if ($('#servicing_non_renew_policy_modal_form')[0].checkValidity()) {
      if (confirm("Are you sure you want submit this renewal decison?")) {
        $('#servicing_non_renew_policy_modal_form').trigger('submit')
        e.disabled = true
        $('#servicing_non_renew_policy_modal_form').addClass('loading')
      } else {
        e.preventDefault()
      }
    } else {
      $('#servicing_non_renew_policy_modal_form')[0].reportValidity()
    }
  })

  $('#servicing_premium_audit_outcome_submit').on('click', (e)=>{
    let $clicked = $(e.target)
    if ($('#servicing_premium_audit_task_form')[0].checkValidity()) {
      if (confirm("Are you sure you want to submit this outcome with the selected documents?")) {
        $('#servicing_premium_audit_task_form').trigger('submit')
        $clicked.disabled = true
        $('#servicing_premium_audit_task_form').addClass('loading')
      } else {
        e.preventDefault()
      }
    }
  })

  $('.servicing_remove_supporting_document_modal_submit').on('click', (e)=> {
    let $clicked = $(e.target)
    let document_uuid = $clicked.data('document_uuid')

    if ($(`#servicing_remove_supporting_document_modal_${document_uuid}_form`)[0].checkValidity()) {
      if (confirm("Are you sure you want to remove this document?")) {
        $(`#servicing_remove_supporting_document_modal_${document_uuid}_form`).trigger('submit')
        $clicked.disabled = true
        $(`#servicing_remove_supporting_document_modal_${document_uuid}_form`).addClass('loading')
      } else {
        e.preventDefault()
      }
    } else {
      $(`#servicing_remove_supporting_document_modal_${document_uuid}_form`)[0].reportValidity()
    }
  })

  $('#endorse_policy_link').on('click', (e)=> {
    e.preventDefault()
    if (confirm("Are you sure you want to endorse this policy?")) {
      $.ajax({
        url: e.target.href,
        type: 'POST',
        dataType: "JSON",
        data: {},
        processData: false,
        contentType: false,
        success: (res) => {
          // console.log(res)
          if (res['success']) {
            window.location.replace(res['application_url'])
          } else {
            console.error(res)
            alert("Failed to endorse this policy: " + res['error'])
          }
        },
        error: (res) => {
          console.error(res)
          alert("Failed to endorse this policy. Please contact the Blitz tech team if issue persists.")
        }
      })
    }
  })
  $('#extend_policy_link').on('click', (e)=> {
    e.preventDefault()
    if (confirm("Are you sure you want to extend this policy?")) {
      $.ajax({
        url: e.target.href,
        type: 'POST',
        dataType: "JSON",
        data: {},
        processData: false,
        contentType: false,
        success: (res) => {
          // console.log(res)
          if (res['success']) {
            window.location.replace(res['application_url'])
          } else {
            console.error(res)
            alert("Failed to extend this policy: " + res['error'])
          }
        },
        error: (res) => {
          console.error(res)
          alert("Failed to extend this policy. Please contact the Blitz tech team if issue persists.")
        }
      })
    }
  })
  $('#audit_policy_link').on('click', (e)=> {
    e.preventDefault()
    if (confirm("Are you sure you want to audit this policy?")) {
      $.ajax({
        url: e.target.href,
        type: 'POST',
        dataType: "JSON",
        data: {},
        processData: false,
        contentType: false,
        success: (res) => {
          // console.log(res)
          if (res['success']) {
            window.location.replace(res['application_url'])
          } else {
            console.error(res)
            alert("Failed to audit this policy: " + res['error'])
          }
        },
        error: (res) => {
          console.error(res)
          alert("Failed to audit this policy. Please contact the Blitz tech team if issue persists.")
        }
      })
    }
  })
  $('#renew_policy_link').on('click', (e)=> {
    e.preventDefault()
    if (confirm("Are you sure you want to renew this policy?")) {
      $.ajax({
        url: e.target.href,
        type: 'POST',
        dataType: "JSON",
        data: {},
        processData: false,
        contentType: false,
        success: (res) => {
          // console.log(res)
          if (res['success']) {
            window.location.replace(res['application_url'])
          } else {
            console.error(res)
            alert("Failed to renew this policy: " + res['error'])
          }
        },
        error: (res) => {
          console.error(res)
          alert("Failed to renew this policy. Please contact the Blitz tech team if issue persists.")
        }
      })
    }
  })

})
